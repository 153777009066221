












































































































import Vue from 'vue'
import { Forecast } from '../models/Forecast';


interface Data {
    loading: boolean,
    post: Array<Forecast>,
    ecosystem: Array<Object>,
    importantLinks: Array<Object>,
    whatsNext: Array<Object>,
}
export default Vue.extend({
    name: 'HelloWorld',
    computed: {
        apiurl() {
            return process.env.VUE_APP_API_URL
        }
    },
    data(): Data {
        return {
            loading: false,
            post: [],
            ecosystem: [
                {
                    text: 'vuetify-loader',
                    href: 'https://github.com/vuetifyjs/vuetify-loader',
                },
                {
                    text: 'github',
                    href: 'https://github.com/vuetifyjs/vuetify',
                },
                {
                    text: 'awesome-vuetify',
                    href: 'https://github.com/vuetifyjs/awesome-vuetify',
                },
            ],
            importantLinks: [
                {
                    text: 'Documentation',
                    href: 'https://vuetifyjs.com',
                },
                {
                    text: 'Chat',
                    href: 'https://community.vuetifyjs.com',
                },
                {
                    text: 'Made with Vuetify',
                    href: 'https://madewithvuejs.com/vuetify',
                },
                {
                    text: 'Twitter',
                    href: 'https://twitter.com/vuetifyjs',
                },
                {
                    text: 'Articles',
                    href: 'https://medium.com/vuetify',
                },
            ],
            whatsNext: [
                {
                    text: 'Explore components',
                    href: 'https://vuetifyjs.com/components/api-explorer',
                },
                {
                    text: 'Select a layout',
                    href: 'https://vuetifyjs.com/getting-started/pre-made-layouts',
                },
                {
                    text: 'Frequently Asked Questions',
                    href: 'https://vuetifyjs.com/getting-started/frequently-asked-questions',
                },
            ],
        }
    },
    created() {
        // fetch the data when the view is created and the data is
        // already being observed
        this.fetchData();
    },
    methods: {
        fetchData(): void {
            this.post = [];
            this.loading = true;

            fetch(`${this.apiurl}weatherforecast`)
                .then(r => r.json())
                .then(r => {
                    this.post = r;
                })
        }
    },
})
