

































































































































import Vue from 'vue';

export default Vue.extend({
    name: 'App',

    data: () => ({
        drawer: null,
        systemBar: false,
    }),
    computed: {
        showSystemBar() : boolean {
            return this.systemBar
        },
        showUserAvatar(): boolean {
            return this.userAvatarUrl.length > 0;
        },
        userAvatarUrl(): string {
            return 'https://cdn.sikoradigital.com/arswebdevelopment.com/img/adam-sikora-face.jpg';
        },
        profileUrl(): string {
            return '/profile';
        },
        calendarUrl(): string {
            return '/calendar';
        },
        statsUrl(): string {
            return '/stats';
        },
        settingsUrl(): string {
            return '/settings';
        },
        billingUrl(): string {
            return '/billing';
        },
        locationsUrl(): string {
            return '/locations';
        },
    },
});
